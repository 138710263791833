@import '~@cloudscape-design/design-tokens/index';

body.awsui-dark-mode {
  background: $color-background-layout-main;
}

.rbc-toolbar button {
  cursor: pointer;
}

/* Calendar css override for dark mode */
.awsui-dark-mode .rbc-today {
  background-color: #1e2f48; 
}

.awsui-dark-mode .rbc-off-range-bg {
  background-color: #2a3b4d;
}

.awsui-dark-mode .rbc-toolbar button {
  color: $color-text-label;
}

.awsui-dark-mode .rbc-toolbar button:hover {
  color: #000716;
}

/* Html5-QRCode override */
.awsui-dark-mode #nav-scanner img, .awsui-dark-mode #start-modal-travelers-input img {
  filter: invert(1);
}

.awsui-dark-mode #nav-scanner__header_message, .awsui-dark-mode #start-modal-travelers-input__header_message {
  color: $color-text-label !important;
}

// fix table style on the alert message
table[aria-label="tableOnWarning"] th {
  background-color: inherit !important;
  color: inherit !important;
}
table[aria-label="tableOnWarning"] tr:not(:last-child) td {
  border-block-end-color: $color-border-divider-default !important;
}