.stripe-input-label {
  margin-bottom: 0.25rem;
  font-weight: 300;
  color: #30313D;
  font-size: 0.93rem;
  transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1), opacity 0.5s cubic-bezier(0.19, 1, 0.22, 1);
}

.awsui-dark-mode .stripe-input-label {
  color: #ffffff;
}

.stripe-input {
  padding: 0.75rem;
  background-color: #fff;
  border-radius: 5px;
  transition: background 0.15s ease, border 0.15s ease, box-shadow 0.15s ease, color 0.15s ease;
  border: 1px solid #e6e6e6;
  font-size: 16px;
  font-weight: 300;
  appearance: none;
  width: 100%;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02);
  display: block;
}

.stripe-input:focus {
  outline: 0px;
  border-color: #0570de;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02), 0 0 0 3px hsla(210, 96%, 45%, 25%), 0 1px 1px 0 rgba(0, 0, 0, 0.08);
}


.awsui-dark-mode .stripe-input {
  background-color: #30313d;
  border-color: #424353;
  color: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5), 0px 1px 6px rgba(0, 0, 0, 0.25);
}

.awsui-dark-mode .stripe-input:focus {
  outline: 0px;
  border-color: #85d996;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5), 0px 1px 6px rgba(0, 0, 0, 0.25), 0 0 0 3px hsla(132, 53%, 69%, 25%), 0 1px 1px 0 rgba(255, 255, 255, 0.12);
}