.table_container {
  overflow-x: auto;
}

.line_item_table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 8px;
  overflow: hidden;
}


.line_item_table td {
  padding: 8px;
}

.line_item_table thead {
  border-bottom: 1px solid;
  border-color: inherit;
}

.line_item_table th {
  position: relative;
  padding: 8px;
}

.line_item_table th::after {
  content: "";
  position: absolute;
  right: 0;
  top: 25%;
  height: 50%;
  width: 2px;
  background-color: #b6bec9;
}

.line_item_table th:last-child::after {
  display: none;
}

.line_item_table tbody tr {
  vertical-align: top;
  border-bottom: 1px solid;
  border-color: inherit;
}
