@import '~@cloudscape-design/design-tokens/index';

// === VARIABLES ==============================
$border-width: 1px;
$heading-5to4-size: 19.74px;
$heading-5to4-lineHeight: 1.06em;
$text-size: 14px;
$text-lineHeight: 1.2em;

// === INTERNAL VARIABLES =====================
$_pad-block: 15px;
$_indicator-size: 10px;
// colors
$_clr-passed: $color-charts-green-400;
$_clr-reject: $color-charts-red-500;
$_clr-tuning: $color-charts-orange-400;
$_clr-onDeck-border: $color-charts-purple-400;
$_clr-onDeck-background: $color-charts-purple-300;
$_clr-nonexistent: $color-border-divider-default;
// chips
$_chip-gap: 1px;
$_chip-border-width: 1.5px;
$_chip-prevInspection-height: 12px;
$_chip-curInspection-incomplete-height: 14px;
$_chip-curInspection-completed-height: 26px;
$_chip-hover-scale: 1.3;
$_chip-horizontal-divider-width: 2px;
$_range-indicator-height: 12px;
$_range-height: calc($_range-indicator-height + 5px);

/* === INTERNAL VARIABLES ===================== */
:root {
    --_pad-inline: 25px;
    // --_chipWidth: provided by orderStep.jsx
    // --_chipHeight: provided by orderStep.jsx
}

/* === RESETS ================================= */
*, *::before, *::after {
    box-sizing: border-box;
}

p {
    margin: 0;
}

ul, ol {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

/* === GLOBAL ================================= */
.visuallyHidden {
  // for when an element should be present in the DOM
  // but hidden visually. This different than display: none;
  position: absolute;
  white-space: nowrap;
  clip: rect(0 0 0 0);

  width: 1px;
  height: 1px;

  padding: 0;
  border: 0;
  margin: 0;

  overflow: hidden;
}

/* === INSPECTION COLORS ====================== */
.passed {
    /* internal variables */
    --_border: none;
    --_background-color: #{$_clr-passed};
}

.rejectMajor {
    /* internal variables */
    --_border: none;
    --_background-color: #{$_clr-reject};
}

.rejectCosmetic {
    /* internal variables */
    --_border: solid #{$_chip-border-width} #{$_clr-reject};
    --_background-color: transparent;
}

.tuning {
    /* internal variables */
    --_border: none;
    --_background-color: #{$_clr-tuning};
}

.toBeInspected {
    /* internal variables */
    --_border: solid #{$_chip-border-width} #{$_clr-passed};
    --_background-color: transparent;
}

.toBeInspected.inProgress {
    /* internal variables */
    --_border: solid #{$_chip-border-width} #{$_clr-nonexistent};
}

.onDeck {
    /* internal variables */
    --_border: solid #{$_chip-border-width} #{$_clr-onDeck-border};
    --_background-color: #{$_clr-onDeck-background};
}

.nonexistent {
    /* internal variables */
    --_border: solid #{$_chip-border-width} #{$_clr-nonexistent};
    --_background-color: transparent;
}

.range {
    /* internal variables */
    --_border: none;
    --_background-color: #{$color-charts-blue-2-400};
}

/* === DATUM COMPONENT ======================== */
.datum {
    color: $color-text-body-secondary;

    &::before {
        border: var(--_border, none);
        background-color: var(--_background-color, transparent);
    }

    &.passed::before,
    &.rejectCosmetic::before,
    &.rejectMajor::before,
    &.tuning::before,
    &.toBeInspected::before,
    &.onDeck::before,
    &.range::before {
        content: "";
        display: inline-block;
        width: $_indicator-size;
        height: $_indicator-size;

        margin-inline-end: 5px;
    }

    &.passed .label::after {
        content: "";
    }

    .label {
        color: $color-text-heading-default;
        font-weight: 700;

        &::after {
            content: ": ";
        }
    }

    .largeNumber {
        font-size: $heading-5to4-size;
        font-weight: 400;
        line-height: $heading-5to4-lineHeight;

        &::after {
            content: " ";
        }

        .passed {
            color: $color-background-notification-green;
        }

        .goal::before {
            content: "/";
        }
    }

    .subValue {
        color: $color-charts-blue-2-400;
    }
}

/* === ORDER HEADER COMPONENT ================= */
.orderHeader {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    column-gap: 20px;
    row-gap: 7px;

    padding-block-start: 25px;
    padding-block-end: $_pad-block;
    padding-inline: var(--_pad-inline);

    .start {
        display: flex;
        flex-flow: row wrap;
        gap: 5px;
        
        h3 {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            gap: 5px;

            color: $color-text-heading-default;
            font-size: $heading-5to4-size;
            line-height: $heading-5to4-lineHeight;
            margin: 0;

            span:first-of-type {
                // Cloudscape badge component override
                font-size: 14px !important;
                font-weight: 500 !important;
            }
        }

        p {
            color: $color-text-body-secondary;
            font-size: $heading-5to4-size;
            line-height: $heading-5to4-lineHeight;
            margin: 0;

            &::before {
                content: " - ";
            }
        }
    }
}

/* === CLOUDSCAPE ALERT OVERRIDE ============== */
.awsui-context-alert {
    & > div {
        padding-block: 2px !important;
        padding-inline: var(--_pad-inline) !important;
        border-radius: 0 !important;
        border-inline-start: none !important;
        border-inline-end: none !important;
        border-width: $border-width !important;
    }
}

/* === ORDER STEPS <OL> ======================= */
.orderSteps {
    padding-block-end: 10px;
    border-block-start: solid $border-width $color-border-divider-default;

    &.hasNote {
        border-block-start: none;
    }
}

/* === ORDER STEP COMPONENT =================== */
.orderStep {
    padding-block: $_pad-block;
    padding-inline: var(--_pad-inline);
    border-block-end: solid $border-width $color-border-divider-default;

    &:last-child {
        border-block-end: none;
    }

    .topRow {
        display: flex;
        flex-flow: row wrap;
        align-items: baseline;
        justify-content: space-between;
        column-gap: 30px;
        row-gap: 7px;

        margin-block-start: -0.1em;
        margin-block-end: calc($_pad-block - 0.3em);

        h4 {
            color: $color-text-heading-default;
            font-size: $text-size;
            line-height: $text-lineHeight;
            margin-block: 0;
            margin-inline: 0 auto;

            .number {
                color: $color-text-body-secondary;
                font-weight: 400;
                margin-inline-end: 1ch;
            }
        }

        .stats {
            display: flex;
            flex-flow: row wrap;
            align-items: baseline;
            column-gap: 30px;
            row-gap: 7px;
        }
    }
}

.status {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    column-gap: 20px;
    row-gap: 7px;

    margin-block-end: 7px;
}

.selectedData {
    display: flex;
    flex-flow: row wrap;
    align-items: baseline;
    justify-content: space-between;
    column-gap: 30px;
    row-gap: 7px;
    position: relative;

    padding-block: 7px;
    border-block-start: solid $border-width $color-border-divider-default;

    button.selectAll {
        position: relative;
        z-index: 1;

        color: $color-text-notification-default;
        padding: 2.5px 10px;
        border: none;
        margin-inline-end: auto;
        background-color: $color-charts-blue-2-400;
        border-radius: 3px;

        transition: background-color 0.1s ease;

        cursor: pointer;

        &:hover, &:focus {
            background-color: $color-charts-blue-2-500;

            &::before, &::after {
                // drag handles
                background-color: $color-charts-blue-2-1200;
            }
        }

        &:focus-visible, &:active {
            background-color: $color-charts-blue-2-600;

            &::before, &::after {
                // drag handles
                background-color: $color-charts-blue-2-1200;
            }
        }

        
        &::before, &::after {
            // drag handles
            content: "";

            display: block;
            position: absolute;
            inset-block: 0;
            width: var(--_chipWidth);
            border: solid $border-width $color-background-layout-main;
            border-radius: 100px;
            background-color: $color-charts-blue-2-700;

            transition: background-color 0.1s ease;
        }

        &::before {
            inset-inline-start: calc(-0.5 * var(--_chipWidth));
        }

        &::after {
            inset-inline-end: calc(-0.5 * var(--_chipWidth));
        }
    }

    ul {
        display: flex;
        flex-flow: row wrap;
        align-items: baseline;
        column-gap: 30px;
        row-gap: 7px;
        position: relative;
        z-index: 1;

        background-color: $color-background-layout-main;
    }
}

.chips-container {
    position: relative;

    border-block-start: solid $_chip-horizontal-divider-width $color-border-divider-default;
    margin-block-end: 10px;

    &::before {
        // full width bottom border
        content: "";
        position: absolute;
        inset-block-end: 0;
        inset-inline: 0;
        border-block-end: solid $_chip-horizontal-divider-width $color-border-divider-default;
    }

    &.draggingStart, &.draggingEnd {
        // user is dragging range slider
        cursor: ew-resize;
    }

    &.draggingStart.rangeSlider .chip.highlighted.start .rangeIndicator::after {
        // start slider cap dot while being dragged
        background-color: $color-charts-blue-2-1200;
    }

    &.draggingEnd.rangeSlider .chip.highlighted.end .rangeIndicator::after {
        // end slider cap dot while being dragged
        background-color: $color-charts-blue-2-1200;
    }

    &.invalid {
        // dragging location is invalid
        cursor: not-allowed;
    }

    &.rangeSlider {
        // order steps that have a range slider
        .chip {
            &.hasDate::after {
                // bottom date horizontal line
                content: "";
                position: absolute;
                inset-block-end: 8px;
                inset-inline: 0;
                border-block-end: solid $border-width $color-text-body-secondary;

                -webkit-user-select: none; // Safari
                user-select: none;
                pointer-events: none;
            }
            
            &.last.hasDate::before {
                // end of date vertical separator
                content: "";
                position: absolute;
                inset-block: 0;
                inset-inline-end: calc($_chip-gap / -2);
                border-inline-end: solid $border-width $color-border-divider-default;

                -webkit-user-select: none; // Safari
                user-select: none;
                pointer-events: none;
            }

            &.highlighted {
                // chips within the selected range
                &.start, &.end {
                    .rangeIndicator {
                        // range slider drag handles
                        cursor: ew-resize;

                        // disable browser gesture handling to allow dragging
                        // to work on touch screens
                        touch-action: none;

                        &:hover, &:focus, &:active {
                            &::after {
                                // drag handles
                                background-color: $color-charts-blue-2-1200;
                            }
                        }

                        &::after {
                            // drag handles
                            content: "";
                            position: absolute;
                            inset: 0;
                            inset-block-end: unset;

                            height: $_range-indicator-height;
                            border: solid $border-width $color-background-layout-main;
                            border-radius: 100px;
                            background-color: $color-charts-blue-2-700;

                            transition: background-color 0.1s ease;
                        }
                    }
                }

                &.start .rangeIndicator::before {
                    // start drag handle background adjustment
                    inset-inline-start: calc($_range-indicator-height / 2);
                }

                &.end .rangeIndicator::before {
                    // end drag handle background adjustment
                    inset-inline-end: calc($_range-indicator-height / 2);
                }

                .rangeIndicator {
                    position: relative;

                    &::before {
                        // indicator bar
                        content: "";
                        position: absolute;
                        inset-inline: 0;
                        inset-block-start: $border-width;

                        height: calc($_range-indicator-height - 2 * $border-width);
                        background-color: $color-charts-blue-2-400;
                    }
                }

                a {
                    // un-dim existent chips that are witin the selected range
                    opacity: 2;
                }

                .overlay {
                    // light blue overlay on highlighted chips
                    display: block;
                    position: absolute;
                    inset: 0;
                    z-index: 1;

                    background-color: $color-charts-blue-2-300;
                    opacity: 0.2;

                    -webkit-user-select: none; // Safari
                    user-select: none;
                    pointer-events: none;
                }
            }

            .rangeIndicator {
                // push range indicator to top
                position: relative;
                z-index: 4;
            }

            a {
                position: relative;
                z-index: 3;

                // dim existent chips by defauult
                opacity: 0.9;
            }

            .date {
                // show date text
                display: block;
                position: absolute;
                inset-block-end: 2px;
                inset-inline-start: -$border-width;
                z-index: 1;

                color: $color-text-body-secondary;
                font-size: 12px;
                line-height: 1em;
                padding-inline: 3px;
                border-inline-start: solid calc(2 * $border-width) $color-text-body-secondary;
                background-color: $color-background-layout-main;

                -webkit-user-select: none; // Safari
                user-select: none;
            }
        }
    }

    .chips {
        display: grid;
        grid-template-columns: repeat(auto-fill, var(--_chipWidth));
    }

    .chip {
        display: flex;
        flex-flow: column nowrap;
        align-items: stretch;
        position: relative;
        height: var(--_chipHeight);

        border-block-end: solid $_chip-horizontal-divider-width $color-border-divider-default;

        &:first-child::before,
        &:last-child::before {
            // vertical separators
            content: "";
            position: absolute;
            inset-block: 0;

            pointer-events: none;
        }

        &:first-child::before {
            // start vertical separator
            inset-inline-start: calc($_chip-gap / -2);
            border-inline-start: solid $border-width $color-border-divider-default;
        }

        &:last-child::before {
            // end vertical separators
            inset-inline-end: calc($_chip-gap / -2);
            border-inline-end: solid $border-width $color-border-divider-default;
        }

        .rangeIndicator {
            height: $_range-height;
        }

        a, .chipWrapper {
            display: flex;
            flex-flow: column nowrap;
            align-items: stretch;
            justify-content: start;
            width: var(--_chipWidth);
            height: calc($_chip-prevInspection-height + $_chip-curInspection-completed-height * $_chip-hover-scale);

            padding-inline: calc(0.5 * $_chip-gap);
            
            .prevInspection {
                height: $_chip-prevInspection-height;
                border: var(--_border, none);
                background-color: var(--_background-color, transparent);

                &.nonexistent {
                    height: calc($_chip-prevInspection-height + $_chip-curInspection-incomplete-height);

                    & + .curInspection {
                        display: none;
                    }
                }
            }

            .curInspection {
                height: $_chip-curInspection-incomplete-height;
                border: var(--_border, none);
                background-color: var(--_background-color, transparent);

                transform-origin: top center;
                transition: height 0.1s ease-out,
                            transform 0.1s ease-out;

                &.completed {
                    height: $_chip-curInspection-completed-height;
                }
            }
        }

        a {
            &:hover, &:focus, &:active {
                .curInspection {
                    transform: scaleY($_chip-hover-scale);

                    &.nonexistent {
                        transform: none;
                    }
                }
            }

            div, p {
                -webkit-user-select: none; // Safari
                user-select: none;
                pointer-events: none;
            }
        }

        .date {
            // hide date text by default
            display: none;
        }

        .overlay {
            // hide highlight overlays by default
            display: none;
        }
    }
}

/* === MEDIA QUERIES ========================== */
@media screen and (max-width: 478px) {
    :root {
        --_pad-inline: 20px;
    }

    .status {
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (max-width: 415px) {
    :root {
        --_pad-inline: 20px;
    }
}

@media screen and (max-width: 390px) {
    :root {
        --_pad-inline: 15px;
    }

    .status {
        grid-template-columns: 1fr;
    }
}