.page-blocker {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #1e1e1e;
  color: #ffcc00;
  overflow: hidden;
  text-align: center;
  z-index: 9999;
  transition: transform 0.5s ease;
}

.spinContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  border-radius: 50%;
  padding: 10vh;
  width: 100%;
  height: 100%;
}

.spinning {
  animation: spin 5s linear infinite;
}

.fade-out {
  opacity: 0;
  transition: opacity 1s ease-out;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.stop-button {
  padding: 1rem 2rem;
  font-size: 1.5rem;
  background-color: #ff4444;
  border: none;
  border-radius: 16px;
  cursor: pointer;
  z-index: 10;
  transition: top 0.3s ease, left 0.3s ease;
}

.bottom-right {
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.gif-container {
  width: 100%;
  height: 50vh;
  pointer-events: none;
  background: url('https://media1.giphy.com/media/v1.Y2lkPTc5MGI3NjExa255MG5qcHdxN280NGwzdWhldjB3ZXJvY2ZndDVkNW81cHNvbXZmaiZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/AF9KxNAhZ7HXi/giphy.gif') center center / cover no-repeat;
  background-size: contain; /* Adjusts to fit inside without cropping */
  animation: fadeInGif 1s ease-in-out forwards;
}

@keyframes fadeInGif {
  0% { opacity: 0; }
  100% { opacity: 1; }
}